<template>
  <el-dialog
    title="物流信息"
    width="1024px"
    top="25vh"
    :visible="visible"
    @open="openDialog"
    @close="closeDialog"
    @closed="initData"
    :modal-append-to-body="false"
    :fullscreen="false"
    center
  >
    <div v-loading="loading" style="min-height: 200px">
      <div class="track" v-if="logisticsInfo.orderTrack">
        <div class="track-lcol">
          <div class="p-item">
            <div class="p-info">
              <ul
                v-for="(item, index) in logisticsInfo.waybillCode"
                :key="index"
              >
                <li>送货方式：{{ item.carrier }}</li>
                <li>货运单号：{{ item.deliveryOrderId }}</li>
                <!-- <li>预计送达时间：{{logisticsInfo.AchieveDetails}}</li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="track-rcol">
          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(item, index) in logisticsInfo.orderTrack"
              :key="index"
              :timestamp="item.msgTime"
              placement="top"
            >
              <el-card>
                <h4>{{ item.operator }}</h4>
                <p>{{ item.content }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <el-empty v-else-if="!loading" description="暂无物流信息"></el-empty>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    thirdOrderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logisticsInfo: {
        // orderTrack: [
        //   {
        //     content: "您提交了订单，请等待系统确认",
        //     msgTime: "2021-12-10 15:21:24",
        //     operator: "客户",
        //   },
        //   {
        //     content: "您的订单已经进入京东北京仓库准备出库",
        //     msgTime: "2021-12-10 15:21:42",
        //     operator: "系统",
        //   },
        //   {
        //     content: "您的订单预计2021-12-10 15:21开始处理，请您耐心等待",
        //     msgTime: "2021-12-10 15:21:42",
        //     operator: "系统",
        //   },
        //   {
        //     content: "温馨提示：您的订单预计12月11日09:00-15:00送达您手中",
        //     msgTime: "2021-12-10 15:21:53",
        //     operator: "系统",
        //   },
        //   {
        //     content: "您的订单已经打印完成",
        //     msgTime: "2021-12-10 15:25:49",
        //     operator: "zhangjiandi",
        //   },
        //   {
        //     content: "拣货完成",
        //     msgTime: "2021-12-10 15:37:52",
        //     operator: "李卫东",
        //   },
        //   {
        //     content: "扫描完成",
        //     msgTime: "2021-12-10 15:46:50",
        //     operator: "刘巧茹",
        //   },
        //   {
        //     content: "打包完成",
        //     msgTime: "2021-12-10 15:46:50",
        //     operator: "京东打包员",
        //   },
        //   {
        //     content: "您的订单在京东【杨镇电缆厂接货仓】分拣完成",
        //     msgTime: "2021-12-10 15:51:04",
        //     operator: "王海玲",
        //   },
        //   {
        //     content:
        //       "您的订单由京东【杨镇电缆厂接货仓】准备送往【北京顺义分拣中心】",
        //     msgTime: "2021-12-10 15:51:09",
        //     operator: "王海玲",
        //   },
        // ],
        // jdOrderId: 234217274410,
        // waybillCode: [
        //   {
        //     carrier: "京东快递",
        //     deliveryOrderId: "JD0059890768374",
        //     orderId: "234217274410",
        //     parentId: "0",
        //   },
        // ],
      },
      loading: true,
      orderId: 0,
    };
  },
  watch: {
    // orderId(e) {
    //   if (e) {
    //     if (e > 0) {
    //       this.initData();
    //     }
    //   }
    // },
  },
  methods: {
    openDialog() {
      this.orderId = this.id;
      this.getData();
    },
    closeDialog() {
      this.$emit("update:visible", false);
    },
    initData() {
      this.orderId = 0;
      this.logisticsInfo = {};
    },
    getData() {
      this.loading = true;
      this.$axios
        .get("/api/GoodsOrder/OrderTrack", {
          params: {
            orderId: this.orderId,
            thirdOrderId:this.thirdOrderId
          },
        })
        .then((res) => {
          this.logisticsInfo = res.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.track {
  overflow: hidden;
  display: flex;
  align-items: center;
  .track-lcol {
    width: 685px;
    float: left;
    padding: 50px 20px 20px;
    .p-item {
      display: inline-block;
      display: block;
      margin-bottom: 30px;
      background: url(//misc.360buyimg.com/user/order/0.0.1/css/i/pimg-bg.png)
        50px top no-repeat;
      //   .p-img {
      //     float: left;
      //     width: 100px;
      //     height: 100px;
      //     line-height: 100px;
      //     text-align: center;
      //     margin: 4px 0 0 50px;
      //     border: 1px solid #efefef;
      //   }
      .p-info {
        float: left;
        // width: 178px;
        margin: 10px 0 0;
        line-height: 25px;
        color: #333;
        word-break: break-all;
        padding-left: 50px;
      }
    }
  }
  .track-rcol {
    float: left;
    border-left: 1px solid #eee;
    max-height: 30vh;
    overflow: auto;
    padding-left: 20px;
    width: 100%;
  }
}
</style>