<template>
  <div class="progress-wrap">
    <div class="progress" :style="'width:'+176*progresses.length+'px;'">
      <div
        v-for="(item, index) in progresses"
        :key="index"
        :class="['item', { active: item.value }, { last: index == lastIndex }]"
      >
        <div class="name">{{ item.name }}</div>
        <div class="time" v-if="item.value">{{ item.value | filterTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Object,
      default() {
        return {};
      },
    },
    status: {
      type: Number,
      default: 0,
    },
    cancelType:{
       type: Number,
      default: 0,
    }
  },
  data() {
    return {
      lastIndex: 0,
    };
  },
  computed: {
    progresses() {
      var steps = [];
      if (this.status == 7) {
        steps = [
          {
            name: "下单",
            key: "createTime",
          },
          {
            name: "订单取消",
            key: "receiptedTime",
          },
        ];
        if(this.cancelType==0){
          steps[1].name="超时未兑换"
        }
      } else {
        steps = [
          {
            name: "下单",
            key: "createTime",
          },
          {
            name: "付款",
            key: "paidTime",
          },
          {
            name: "发货",
            key: "takeTime",
          },
          {
            name: "交易成功",
            key: "receiptedTime",
          },
        ];
      }
      console.log(this.cancelType,steps)
      const array = [];
      let that = this;
      steps.forEach((e, i) => {
        array.push({
          name: e.name,
          value: this.time[e.key],
        });

        if (this.time[e.key]) {
          that.lastIndex = i;
        }
      });
      return array;
    },
  },
  filters: {
    filterTime(datetime) {
      const units = ["-", "-", ""];
      datetime = datetime.replace("T", " ");
      datetime = datetime.split(" ");
      return (
        datetime[0]
          .split("-")
          .map((e, i) => e + units[i])
          .join("") +
        " " +
        datetime[1].substring(0, 8)
      );
    },
  },
};
</script>

<style lang="scss">
.progress-wrap {
  padding-bottom: 40px;

  .progress {
    width: 700px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #eee;
    margin: 0 auto;
    @extend %flex-align-center;

    .item {
      width: 176px;
      height: 20px;
      text-align: center;
      position: relative;

      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &.active {
        background-color: $color-success;

        .name {
          color: #fff;
        }
      }

      &.last {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .name {
        color: $text-color;
        font-size: 16px;
      }

      .time {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 15px;
        color: #757575;
      }
    }
  }
}
</style>
