<template>
  <div>
 
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="order/index">
      <section
        v-loading="loading"
        :hidden="!loading"
        style="height: 100%"
      ></section>
      <div v-if="order.id">
        <div class="secondTitle">订单详情</div>
        <div class="order-header">
          <div class="orderno">
            订单号：<span style="user-select: text">{{ order.orderNo }}</span>
          </div>
          <div class="btn-wrap">
            <div class="btn" @click="hrefTencent">联系客服</div>
            <div v-if="order.status == 0 || order.status == 11" class="btn" @click="orderCancel">
              取消订单
            </div>
            <!-- <div
              class="btn"
              v-if="order.status > 1 && order.goodsList[0].GoodsFromType == 1"
              @click="logisticsLook"
            >
              查看物流
            </div> -->
            <!-- <div
              v-if="order.status == 2 || order.status == 34"
              class="btn"
              @click="orderRefund"
            >
              申请退款
            </div>
            <div v-if="order.status == 8 || order.status == 10" class="btn">
              申请售后
            </div> -->
            <div
              v-if="order.status == 2 && order.goodsList[0].GoodsFromType == 0"
              class="btn btn-primary"
              @click="confirmReceipt"
            >
              确认已收货
            </div>
            <span
              v-if="order.status == 0"
              class="btn btn-primary"
              @click="payclick"
            >
              立即兑换</span
            >
            <!-- <router-link
              v-if="order.status == 0"
              class="btn btn-primary"
              :to="`/order/pay?orderNo=${orderNo}`"
              >立即兑换</router-link
            > -->
          </div>
        </div>
        <div
          :class="['order-status', orderDesc(order.status)]"
          :title="order.remark"
        >
          {{ order.statusDesc }}
        </div>
        <order-progress
          :time="order.timeInfo"
          :status="order.status"
          :cancelType="order.cancelType"
        ></order-progress>
        <!-- <div class="express-wrap" v-if="order.expressInfo.name">
          <span class="item">物流公司：</span>
          <a
            class="item link"
            :href="order.expressInfo.linkUrl"
            target="_blank"
          >
            <span>{{ order.expressInfo.name }}</span>
            <i class="icon i-right-double"></i>
          </a>
          <span class="item" style="user-select: text"
            >运单号：{{ order.expressInfo.waybillNo }}</span
          >
        </div> -->
        <div class="goods-wrap" v-for="(item, index) in order.goodsList"
            :key="index">
          <div class="express-wrap" v-if="item.ExpressType">
          <span class="item">物流公司：</span>
          <a
            class="item link"
            :href="order.expressInfo.linkUrl"
            target="_blank"
          >
            <span>{{ item.ExpressType}}</span>
            <i class="icon i-right-double"></i>
          </a>
          <span class="item" style="user-select: text"
            >运单号：{{ item.ExpressNumber}}</span
          >
        </div>
          <div
            class="goods"
            
          >
            <a :href="`#/goods/info?productID=${item.GoodsId}`" target="_blank">
              <el-image class="thumb" :src="item.Photo">
                <div slot="error" class="image-slot">
                  <img
                    src="@/assets/imgs/imgError.jpeg"
                    width="80px"
                    height="80px"
                  />
                </div>
              </el-image>
              <!-- <img class="thumb" :src="item.thumbUrl"/> -->
            </a>
            <a :href="`#/goods/info?productID=${item.GoodsId}`" target="_blank">
              <div class="name elip" :title="item.Name">
                <span
                  :class="['product-tag', { isjd: item.GoodsFromType == 1 }]"
                  >{{ item.GoodsFromType == 0 ? "严选" : "智选" }}</span
                >{{ item.Name }}
              </div>
              <div class="skuName" :title="item.SkuNameA">
                {{ item.SkuName }}
              </div>
            </a>
            <div class="subtotal">
              {{ item.Price.toFixed(2) + "" + $currency }} x {{ item.Numbers }}
            </div>
            <div :class="['order-desc', orderDesc(item.OrderStatus)]">
              {{ orderStatusText(item.OrderStatus) }}
            </div>
            <div
              v-if="
                item.OrderStatus > 1 &&
                item.OrderStatus != 7 &&
                item.GoodsFromType == 1
              "
              class="logistics-btn"
              @click="logisticsLook(item.ThirdOrderId)"
            >
              <span class="btn">查看物流</span>
            </div>
          </div>
        </div>
        <div class="other-details">
          <div class="detail-title">收货信息</div>
          <div class="item">
            <div class="name">
              姓<span style="width: 28px; display: inline-block"></span>名：
            </div>
            <div class="value">{{ order.shippingInfo.consignee }}</div>
          </div>
          <div class="item">
            <div class="name">联系电话：</div>
            <div class="value">{{ order.shippingInfo.phone }}</div>
          </div>
          <div class="item">
            <div class="name">收货地址：</div>
            <div class="value">
              {{ order.shippingInfo.province }} {{ order.shippingInfo.city }}
              {{ order.shippingInfo.district }} {{order.shippingInfo.town }} {{ order.shippingInfo.address }}
            </div>
          </div>
        </div>
        <div class="other-details" v-if="order.payMode">
          <div class="detail-title">支付方式</div>
          <div class="item">
            <div class="name">支付方式：</div>
            <div class="value">{{ order.payMode }}</div>
          </div>
        </div>
        <div class="total-amount">
          <div class="item">
            <div class="name">商品总价：</div>
            <div class="value">
              {{ order.goodsAmount + "" + $currency }}
            </div>
          </div>
          <div class="item">
            <div class="name">运费：</div>
            <div class="value">
              {{
                order.expressFee == 0
                  ? "包邮"
                  : order.expressFee + "" + $currency
              }}
            </div>
          </div>
          <div class="item" v-if="order.discountAmount > 0">
            <div class="name">优惠：</div>
            <div class="value">
              {{ order.discountAmount + "" + $currency }}
            </div>
          </div>
          <div class="item">
            <div class="name">云淘豆：</div>
            <div class="value">
              <span class="pay-amount">{{
                order.payAmount + "" + $currency
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-empty v-else-if="!loading" :image-size="200"></el-empty>
    </menu-wrapper>
    <payIndex :visible.sync="visible" :id="clickOrderId"></payIndex>
    <logistics
      :visible.sync="logisVisible"
      :id="Number(clickOrderId)"
      :thirdOrderId="thirdOrderId"
    ></logistics>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import orderProgress from "@/components/order/order-progress.vue";
import payIndex from "@/views/order/pay/index";
import logistics from "@/views/order/modules/logistics.vue";
import { mapActions } from "vuex";
export default {
  components: {
    menuWrapper,
    orderProgress,
    payIndex,
    logistics,
  },
  data() {
    return {
      order: {},
      loading: true,
      breadcrumbs: [
        {
          name: "商品订单",
          linkUrl: "index",
        },
        {
          name: "订单详情",
          linkUrl: "detail",
        },
      ],
      orderNo: 0,
      visible: false,
      clickOrderId: "0",
      logisVisible: false,
      thirdOrderId: "",
    };
  },
  activated() {
    // const qurey=this.$route.qurey
    this.orderNo = this.$route.query.orderNo;
    this.findDetails();
  },
  computed: {
    orderDesc() {
      return (status) => {
        const warnging = [0, 6];
        const green = [2, 3, 5, 9];
        if (warnging.indexOf(status) > -1) {
          return "warnging";
        } else if (green.indexOf(status) > -1) {
          return "green";
        }
        return "";
      };
    },
  },
  methods: {
    ...mapActions(["setOrderInfo"]),
    logisticsLook(thirdOrderId = 0) {
      this.logisVisible = true;
      this.thirdOrderId = thirdOrderId;
    },
    payclick() {
      this.visible = true;
    },
    findDetails() {
      this.loading = true;
      this.$axios
        .get("/api/GoodsOrder/OrderDetail", {
          params: {
            UserID: this.$store.getters.getUserId,
            OrderID: this.orderNo,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data[0];
            const goodsAmount = data.GoodsMonry; //data.OrderPrice
            const expressFee = data.PostFee;

            let payAmount = data.Money ? data.Money : goodsAmount + expressFee;
            const mode = {
              id: data.Id,
              status: data.OrderStatus,
              statusDesc: this.orderStatusText(data.OrderStatus),
              orderNo: data.OrderId,
              payAmount: payAmount.toFixed(2),
              remark: data.Remarks,
              goodsList: data.GoodsOrders,
              timeInfo: {
                createTime: data.CreateTime,
                paidTime: data.PayTime,
                takeTime: data.TakeDate,
                receiptedTime: data.EndDate,
              },
              shippingInfo: {
                consignee: data.AddressName,
                phone: data.AddressPhone,
                province: data.Province,
                city: data.City,
                district: data.District,
                address: data.Address,
                town:data.Town,
              },
              goodsAmount: goodsAmount.toFixed(2),
              expressFee: expressFee.toFixed(2),
              discountAmount: 0,
              payMode: "",
              expressInfo: {
                linkUrl: "javascript:void(0);",
                waybillNo: data.ExpressNumber,
                name: data.ExpressType,
              },
              cancelType:data.CancelType
            };
            
            this.order = mode;
            this.loading = false;
            let createTime;
            if(data.modify_date){
              createTime=data.modify_date
            }
            else{
              createTime=data.CreateTime
            }
            createTime =createTime.replace("T", " ");
            
            // 计算到期时间
            const endTime = this.$util.getOrderEndTime(createTime);
            const modes = {
              createTime: createTime,
              payAmount: payAmount.toFixed(2),
              id: data.Id,
              endTime: endTime,
              orderNo: data.OrderId,
              status: data.OrderStatus,
              isDelte: false,
            };
            this.clickOrderId = data.Id.toString();
            this.setOrderInfo(modes);
          }
        });
    },
    confirmReceipt() {
      this.$alert("确定已收货吗？", "", {
        closeOnClickModal: true,
      }).then(() => {
        this.loading = true;
        this.$axios
          .put(
            "/api/GoodsOrder/DeliveryGood",
            this.qs.stringify({ OrderID: this.orderNo })
          )
          .then((res) => {
            if (res.data) {
              this.order = res.data;
              this.$notifySucceed("已确认收货");
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    orderCancel() {
      this.$alert("确定取消订单吗？", "", {
        confirmButtonText: "取消订单",
        closeOnClickModal: true,
      }).then(() => {
        this.loading = true;
        const query = {
          OrderID: this.orderNo,
        };
        this.$axios
          .put("/api/GoodsOrder/CancelOrder", this.qs.stringify(query))
          .then((res) => {
            this.loading = false
            // const data = res.data
            // this.order.status = data.OrderStatus
            this.findDetails()
          });
      });
    },
    orderRefund() {
      this.$alert("确定申请退款吗？", {}, () => {
        // uni.showLoading({
        // 	title: '提交中...',
        // 	mask: true
        // });
        // this.$util.request('/order/refund', {
        // 	id: this.order.id
        // }, (res) => {
        // 	uni.hideLoading();
        // 	if (res.state == 'ok') {
        // 		this.findDetails(this.order.orderNo);
        // 	} else {
        // 		this.$alert(res.msg, {
        // 			showCancel: false
        // 		});
        // 	}
        // });
      });
    },
  },
};
</script>

<style lang="scss">
// .title {
//   font-size: 30px;
//   color: #757575;
//   line-height: 68px;
// }

.order-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  .orderno {
    font-size: 18px;
    color: #333;
  }

  .btn-wrap {
    @extend %flex-align-center;

    .btn {
      margin-left: 10px;
      width: 118px;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      color: #757575;
      border: 1px solid #757575;
      transition: all 0.3s;

      &.btn-primary {
        background-color: $color-primary;
        color: #ffffff;
        border-color: $color-primary;

        &:hover {
          background-color: $color-primary;
          color: #ffffff;
          border-color: $color-primary;
          opacity: 0.9;
        }
      }

      &:hover {
        color: #fff;
        background-color: #757575;
        border-color: #757575;
      }
    }
  }
}

.order-status {
  color: #b0b0b0;
  font-size: 18px;
  margin: 25px 0;

  &.warnging {
    color: $color-primary;
  }

  &.green {
    color: $color-success;
  }
}



.goods-wrap {
  .express-wrap {
  // margin: 15px 20px;
  line-height: 30px;
  // border-bottom: 1px solid #cfeeab;

  .item {
    font-size: 18px;
    color: #757575;
    font-weight: bold;
  }

  .link {
    cursor: pointer;
    margin-right: 10px;

    .icon {
      font-size: 11px;
      margin-left: 2px;
    }

    &:hover {
      color: $color-primary;
    }
  }
}
  margin-top: 20px;
  font-size: 14px;
  // white-space: nowrap;
  .goods {
    color: $text-color;
    //height: 90px;
    border-bottom: 1px solid $border-color;
    @extend %flex-align-center;

    .thumb {
      width: 80px;
      height: 80px;
    }

    .name {
      color: $text-color;
      margin-left: 20px;
      width: 300px;
      padding-right: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        color: $color-primary;
      }

      .product-tag {
        border-radius: 10px;
        background-color: #67c23a;
        color: #f5f5f5;
        padding: 0px 10px;
        font-size: 12px;
        &.isjd {
          background-color: #ff5933;
        }
      }
    }
    .skuName {
      padding-left: 15px;
      color: #757575;
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        color: $color-primary;
      }
    }
    .subtotal {
      width: 150px;
      text-align: center;
    }
    .order-desc {
      width: 100px;
      text-align: right;
      color: #b0b0b0;
      font-weight: bolder;
      margin-left: 50px;
      &.warnging {
        color: $color-primary;
      }

      &.green {
        color: $color-success;
      }
    }
    .logistics-btn {
      width: 150px;
      text-align: right;
      .btn {
        cursor: pointer;
        margin-left: 20px;
        border: 1px solid #757575;
        color: #757575;
        border-radius: 5px;
        padding: 2px 10px;
        font-size: 10px;
        &:hover {
          background-color: #757575;
          border-color: #757575;
          color: #ffffff;
          opacity: 0.9;
        }
      }
    }
  }
}

.other-details {
  min-height: 120px;
  padding: 30px 0 12px;
  border-bottom: 1px solid $border-color;

  .detail-title {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
  }

  .item {
    color: #757575;
    @extend %flex-align-center;
    font-size: 14px;
    .name {
      font-weight: bold;
      padding: 3px 0;
      padding-right: 0.3em;
    }
  }
}

.total-amount {
  padding-top: 30px;

  .item {
    @extend %flex-align-center;
    justify-content: flex-end;
    padding: 5px 0;

    .name {
      color: #757575;
    }

    .value {
      min-width: 120px;
      text-align: right;
      color: $color-primary;
    }

    .pay-amount {
      font-size: 30px;
    }
  }
}
</style>
